<template>
  <div>
    <div class="header">
      <van-nav-bar
        :title="$t('tix.title')"
        left-arrow
        @click-left="$router.go(-1)"
      />
    </div>
    <div class="maincontent">
      <van-form v-model="form">
        <van-row>
          <!--
          <van-cell :title="$t('tix.biz')" center>
            <template #extra>
              <van-dropdown-menu active-color="#004ea3">
                <van-dropdown-item v-model="bizhong" :options="bzoptions" />
              </van-dropdown-menu>
            </template>
          </van-cell>
          -->
          <van-cell :title="$t('tix.lname')" center>
            <template #extra>
              <van-dropdown-menu active-color="#004ea3">
                <van-dropdown-item v-model="productvalue" :options="product" />
              </van-dropdown-menu>
            </template>
          </van-cell>
          <van-field
            v-model="form.url"
            :label="$t('tix.address')"
            :placeholder="$t('tix.enter')"
          />
          <van-field :label="$t('tix.number')" v-model="form.number" clearable v-on:input="updatenum" type="number"
            ><template #button>
              <van-button size="small" type="primary" @click.prevent="all">{{
                $t('tix.allbtn')
              }}</van-button>
            </template>
          </van-field>
          <van-field
            v-model="total"
            label="≈"
            disabled
          />
          <div
            style="width: 100%; text-align: left; padding-left: 13px; margin: 10px 0px;"
          >
            <span style="color: rgb(66, 76, 102);"
              >{{ $t('tix.text') }}{{ balance }} USD
             </span>
             <span style="color: rgb(66, 76, 102);"
             v-if="wallet[productvalue]" 
             >
             ≈ {{(balance/wallet[productvalue].rate).toFixed(4)}} {{wallet[productvalue].wallet_name}}
            </span>
          </div>

          <div
            style="width: 100%; text-align: center; padding-left: 13px; margin: 10px 0px;"
          >
            <span style="color: rgb(236, 29, 46);"
              >{{ $t('tix.txsm') }} </span
            >
          </div>
          <div
            style="width: 100%; text-align: left; padding-left: 13px; margin: 10px 0px;"
          >
            <span style="color: rgb(236, 29, 46);"
              >1.{{ $t('tix.tips1') }}</span
            >
          </div>
          <div
            style="width: 100%; text-align: left; padding-left: 13px; margin: 10px 0px;"
          >
            <span style="color: rgb(236, 29, 46);"
              >2.{{ $t('tix.tips2') }}</span
            >
          </div>
          <div
            style="width: 100%; text-align: left; padding-left: 13px; margin: 10px 0px;"
          >
            <span style="color: rgb(236, 29, 46);"
              >3.{{ $t('tix.tips3') }} {{this.config.withdrawl_min}} USD</span
            >
          </div>
          <div
            style="width: 100%; text-align: left; padding-left: 13px; margin: 10px 0px;"
          >
            <span style="color: rgb(236, 29, 46);"
              >4.{{ $t('tix.tips4') }} {{this.config.withdrawl_max}} USD</span
            >
          </div>
          <div
            style="width: 100%; text-align: left; padding-left: 13px; margin: 10px 0px;"
          >
            <span style="color: rgb(236, 29, 46);"
              >5.{{ $t('tix.tips5') }} {{this.config.today_withdrawl_max}} USD</span
            >
          </div>
          <div
            style="width: 100%; text-align: left; padding-left: 13px; margin: 10px 0px;"
          >
            <span style="color: rgb(236, 29, 46);"
              >6.{{ $t('tix.tips6') }} {{this.config.withdrawl_num}} </span
            >
          </div>
          <div
            style="width: 100%; text-align: left; padding-left: 13px; margin: 10px 0px;"
          >
            <span style="color: rgb(236, 29, 46);"
              >7.{{ $t('tix.tips7') }} {{this.config.withdrawl_times}}  </span
            >
          </div>
        </van-row>
        <van-button class="submitBtns" @click.prevent="submit">{{
          $t('common.submit')
        }}</van-button>
      </van-form>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      form: {
        url: '',
        number: 0
      },
      productvalue:0,
      product: [],
      bizhong: 1,
      bzoptions: [{ text: 'USDT', value: 1 }],
      balance: 0,
      total:'0',
      config: {},
      wallet:[]
    }
  },
  created() {
    this.getinfo()
  },
  methods: {
    async getinfo() {
      const { data } = await this.$http.get('/home/user/withdraw')
      if (data) {
        if (data.code === 200) {
          this.balance = data.data.balance
          this.wallet = data.data.type
          this.config = data.data.config
          this.product = []
          this.wallet.forEach((type, index) => {
            this.product.push({
              text: type.wallet_type.toUpperCase(),
              value: index
            })
          })
          if (this.config.withdrawl_opend === '0') {
            this.$toast.fail(this.$t('tix.erroropen'))
          }
          if (!this.config.attime) {
            this.$toast.fail(
              this.$t('tix.errortime') + this.config.withdrawl_times
            )
          }
        }
      }
    },
    updatenum(vo){
      this.total = (this.form.number / this.wallet[this.productvalue].rate).toFixed(4);
    },
    all() {
      this.form.number = this.balance
      this.updatenum()
    },
    async submit() {
      if (!this.form.url || !this.form.number) {
        this.$toast.fail(this.$t('tix.complate'))
        return false
      }
      const postform = {
        product_type: this.wallet[this.productvalue].wallet_name,
        product_type_id: this.wallet[this.productvalue].product_type_id,
        withdraw_url: this.form.url,
        withdraw_num: this.form.number,
        service_charge: this.config.withdrawl_fee,
        rate:this.wallet[this.productvalue].rate,
      }
      const { data } = await this.$http.post('/home/user/withdraw', postform)
      if (data) {
        if (data.code === 200) {
          this.$toast.success(this.$t('tix.submitok'))
          this.form = {
            url: '',
            number: 0
          }
          this.getinfo()
        } else {
          //console.log(data.msg);
          //console.log(localStorage.getItem('lang'));
          //console.log(this.getlang(data.msg, localStorage.getItem('lang')));
          this.$toast.fail(this.getlang(data.msg, localStorage.getItem('lang')))
          //this.$toast.fail(data.msg)
        }
      }
    }
  }
}
</script>
<style lang="less" scoped>
.maincontent {
  padding-top: 65px;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  font-size: 0.93333rem;
  background: #f7f7f7;
  height: 100vh;
  .van-cell__title {
    text-align: left;
    font-size: 0.93333rem;
  }
  .van-cell {
    font-size: 0.98667rem;
    background-color: #fff !important;
    height: 3.46667rem;

    &:after {
      border-bottom: 0.02667rem solid #d0d0d1;
    }
  }
  .van-dropdown-menu /deep/.van-dropdown-menu__bar {
    box-shadow: none;
    height: 1rem;
    background: transparent !important;
  }
  .van-dropdown-menu /deep/ .van-dropdown-menu__title {
    color: #212121 !important;
  }
  /deep/.van-dropdown-item {
    margin-top: 1.1rem;
  }
  .van-field {
    height: 3.4rem;
    font-size: 0.93333rem;
    /deep/.van-field__label {
      color: #323232;
    }
  }
  .van-cell__value {
    position: relative;
    overflow: hidden;
    color: #969799;
    text-align: right;
    vertical-align: middle;
    word-wrap: break-word;
  }
  /deep/.van-field__error-message--right,
  /deep/.van-field__control,
  /deep/.van-field__error-message {
    text-align: right;
  }
  .message {
    margin-top: 0.8rem;
    font-size: 1.06667rem;
    color: #000;
  }
  .submitBtns {
    margin-top: 20%;
    width: 80%;
    letter-spacing: 0.53333rem;
    height: 4rem;
    font-size: 0.93333rem;
    color: #fff;
    background-color: #004ea3;
    border-radius: 0.53333rem;
    border: none;
    box-shadow: 0 0 1.33333rem #004ea3;
  }
}
</style>
